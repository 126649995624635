//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Events as TaskEvents, TaskEventBus } from '@/components/Tasks/EventBus'
import { mapGetters } from 'vuex'
import { networkLogger } from '@/loggers'
import { Notify } from 'quasar'
import api from '@/api/v3'
import draggable from 'vuedraggable'
import TaskDeskColumn from './TaskDeskColumn'
import {
  tasksStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
} from '@/store'

export default {
  name: 'TaskDesk',
  components: {
    draggable,
    TaskDeskColumn,
  },
  data () {
    return {
      isLoading: true,
      tasksPreloading: false,
      draggedColumnId: null,
      isFirstLoad: true,
    }
  },
  created () {
    this.requestTimeoutId = null
    this.$watch(vm => vm.currentDeskTabKey, this.changeTab, { immediate: false }) // fix double request
    this.$watch(
      vm => [
        vm.currentDeskSortingKey,
        vm.currentDeskFilterKey,
        vm.currentDeskFilters,
      ],
      (newVal, oldVal) => !oldVal && this.update(), // for initial load
      { immediate: true, deep: true },
    )
    this.$watch(
      vm => vm.currentDeskSortingKey,
      (newVal, oldVal) => newVal !== oldVal && this.update(),
      { immediate: false, deep: true },
    )
    this.$watch(
      vm => vm.currentDeskFilterKey,
      (newVal, oldVal) => newVal !== oldVal && this.update(),
      { immediate: false, deep: true },
    )
    this.$watch(
      vm => vm.currentDeskFilters,
      (newVal, oldVal) => {
        if (
          newVal.created_gte !== oldVal.created_gte ||
          newVal.created_lte !== oldVal.created_lte ||
          newVal.deadline_gte !== oldVal.deadline_gte ||
          newVal.deadline_lte !== oldVal.deadline_lte ||
          newVal.done_gte !== oldVal.done_gte ||
          newVal.done_lte !== oldVal.done_lte ||
          newVal.assignee?.length !== oldVal.assignee?.length ||
          newVal.assignee?.some(a => !oldVal.assignee?.includes(a)) ||
          newVal.owner?.length !== oldVal.owner?.length ||
          newVal.owner?.some(o => !oldVal.owner?.includes(o)) ||
          newVal.section?.length !== oldVal.section?.length ||
          newVal.section?.some(s => !oldVal.section?.includes(s)) ||
          newVal.tag?.length !== oldVal.tag?.length ||
          newVal.tag?.some(t => !oldVal.tag?.includes(t)) ||
          newVal.department?.length !== oldVal.department?.length ||
          newVal.department?.some(v => !oldVal.department?.includes(v))
        ) this.update()
      },
      { immediate: false, deep: true },
    )
    this.isFirstLoad = false
  },
  mounted () {
  },
  beforeDestroy () {
    if (this.requestTimeoutId) {
      clearTimeout(this.requestTimeoutId)
      this.requestTimeoutId = null
    }
  },
  computed: {
    ...mapGetters([
      'profile',
      'profileId',
    ]),
    notOrderedGroups () {
      return uiSettingsStore.getters.notOrderedGroups
    },
    alreadyOrderedGroups () {
      return uiSettingsStore.getters.alreadyOrderedGroups
    },
    taskDeskCurrentGroupsOrder () {
      return uiSettingsStore.getters.taskDeskCurrentGroupsOrder
    },
    currentDeskFilterKey () {
      return uiSettingsStore.getters.currentDeskFilterKey
    },
    currentDeskGroups () {
      return uiStore.getters.currentDeskGroups
    },
    currentDeskFilters () {
      return uiStore.getters.currentDeskFilters
    },
    currentDeskTabKey () {
      return uiStore.getters.currentDeskTabKey
    },
    canCreateTask () {
      return this.contact(this.profileId)?.canCreateTask ?? false
    },
    orderedGroups: {
      get () {
        const alreadyOrderedGroups = this.alreadyOrderedGroups

        // it could be the first time a user opens a dashboard
        if (
          alreadyOrderedGroups.length === 0 &&
          !this.isLoading
        ) {
          // attempt to show notification
          this.showNowReordableNotification()

          // save default groups order to settings
          // this will prevent attempting to show notification every time
          // if user does not move around any columns
          this.updateGroupsOrder({
            orderedGroups: this.currentDeskGroups,
            movedColumnKey: this.currentDeskGroups[0].key,
          })
          return this.currentDeskGroups
        }

        const newGroups = this.notOrderedGroups

        return [...newGroups, ...alreadyOrderedGroups]
      },
      set (newOrderedGroups) {
        this.updateGroupsOrder({ orderedGroups: newOrderedGroups })
        this.draggedColumnId = null // just in case
      },
    },
    currentDeskSortingKey () {
      return (
        uiSettingsStore.getters.currentDeskSortingKey ||
        (this.sortEntries[0] || {}).key
      )
    },
    filters () {
      return this.currentTab.filters || []
    },
    sortEntries () {
      return this.currentTab.sort || []
    },
    currentTab () {
      return tasksStore.state.tabs
        ? tasksStore.state.tabs[this.currentDeskTabKey]
        : {}
    },
  },
  methods: {
    setOrderedGroups (groups) {
      const columnNames = {
        status: 'Статус',
        section: 'Проект',
        assignee: 'Исполнитель',
        owner: 'Постановщик',
        tag: 'Тег',
        importance: 'Приоритет',
      }

      window.goal('dashboardControls', {
        dashboardControls: `Колонка «${columnNames[this.currentDeskFilterKey] ?? this.currentDeskFilterKey}» — Перетащить`,
      })

      this.orderedGroups = groups
    },
    handlePan (e) {
      const td = this.$refs.taskDesk
      if (!td || !e?.delta?.x) return
      td.scrollLeft += e.delta.x
    },
    showNowReordableNotification () {
      if (uiSettingsStore.getters.uiSettings.taskDesk.reordableNotificationShown) return

      Notify.create({
        message: this.$t('dashboard.nowReordable.columns'),
        color: 'main-accent',
        textColor: 'white',
        position: 'top',
        timeout: 3000,
        actions: [{ label: this.$t('common.close'), color: 'white' }],
        badgeClass: 'hidden',
      })
      uiSettingsStore.actions.setReordableNotification(true)
    },
    updateGroupsOrder ({
      orderedGroups = this.orderedGroups,
      movedColumnKey = this.draggedColumnId,
    }) {
      uiSettingsStore.actions.setTaskDeskColumnOrder({
        orderedGroups,
        movedColumnKey,
      })
    },
    handleDragStart (evt) {
      this.draggedColumnId = evt?.item?.dataset?.columnId || null
    },
    handleDragEnd () {
      this.draggedColumnId = null
    },
    changeTab () {
      uiStore.actions.taskDeskSetGroup({ groups: null })
      this.update()
    },
    async update () {
      this.isLoading = true
      try {
        const groupByKey = this.currentDeskFilterKey
        const { department, ...other } = this.currentDeskFilters
        const filters = { contact_section: department, ...other }
        let groups = await api.tasks.groupBy(groupByKey, filters)
        groups = groups || []
        groups.forEach(g => (g.tasks = g.tasks ?? []))
        !this.tasksPreloading && this.preloadTasks(groups)

        const preparedGroups = []
        groups.forEach(group => {
          const meta = group.meta || {}
          group.key = meta.jid || meta.section || meta.tag || meta.status?.uid ||
                      meta.importance?.toString() || JSON.stringify(meta)
          group.groupByKey = groupByKey
          group.tasks = group.tasks ?? [] // TODO: move to DataAdapter
          const filterBySection = this.currentDeskFilterKey === 'section'
          const hasNoMeta = Object.keys(group.meta).length === 0
          if (filterBySection && hasNoMeta) { // no meta -> no project. display it first
            group.meta.section = null // no section is NULL section, not undefined
            preparedGroups.unshift(group)
          } else {
            preparedGroups.push(group)
          }
        })

        uiStore.actions.taskDeskSetGroup({ groups: preparedGroups })

        this.isLoading = false
      } catch (e) {
        networkLogger.warn('[TaskDesk.update]', e)
        this.requestTimeoutId = setTimeout(this.update, 5000)
      }
    },
    async preloadTasks (groups) {
      this.tasksPreloading = true

      await Promise.allSettled(groups.map(async group => {
        const tasksNotInStore = group.tasks.filter(
          task => !tasksStore.state.data[task.jid],
        )
        const num = tasksNotInStore
          .slice(0, 30)
          .map(task => task.num)
        if (num.length > 0) return tasksStore.actions.loadTasks({ num })
      }))

      this.tasksPreloading = false
    },
    showNewTaskModal () {
      uiStore.actions.showModal({ instance: 'new-task', payload: null })
    },
  },
  watch: {
    isLoading: {
      immediate: true,
      handler (isLoading) {
        TaskEventBus.$emit(TaskEvents.TASK_DESK_LOADING, isLoading)
      },
    },
    currentDeskFilterKey: {
      immediate: true,
      handler () {
        // TODO: The task board needs to be redone for normal routing,
        //  so that filters are calculated from routes and removed watching
        !this.isFirstLoad && this.$router.push({
          name: 'Dashboard',
          params: {
            teamId: teamsStore.getters.currentTeam.uid,
            groupBy: this.currentDeskFilterKey,
          },
        })
      },
    },
  },
}
