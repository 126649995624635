//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { Notify } from 'quasar'
import { sortTasks } from '@/utils'
import { tasksStore, uiStore } from '@/store'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    TaskBarCardCompact: () => import('./TaskBarCardCompact'),
    TaskBarCardFull: () => import('./TaskBarCardFull'),
    TaskDeskCardCompact: () => import('./TaskDeskCardCompact'),
    TaskDeskCardFull: () => import('./TaskDeskCardFull'),
  },
  props: {
    quickDone: Boolean,
    compactList: Boolean,
    tabKey: String,
    filters: Object,
    sort: String,
    jids: Array,
    wantedTasks: Array,
    notHoverable: Boolean,
    taskDragMetadata: Object,
    groupByKey: String,
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['rightBar', 'taskDesk'].indexOf(value) !== -1
      },
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'task-bar',
    },
  },
  data () {
    return {
      // TODO: ugly! will probably stay like this till adaptive layout is rolling out
      defaultCardsSizes: {
        rightBar: {
          full: { minHeight: '99px' },
          compact: { minHeight: '62px' },
        },
        taskDesk: {
          full: { minHeight: '69px' },
          compact: { minHeight: '63px' },
        },
      },
      seenTasks: [],
      paginationLimit: 30,
      isLoading: false,
    }
  },
  created () {
    // this.loadTasks()
  },
  methods: {
    getTask (jid) {
      return tasksStore.state.data[jid]
    },
    handleDragStart (evt) {
      const dataset = evt?.item?.dataset
      const jid = dataset.jid
      if (!jid) return

      const columnKey = this.draggedTaskFieldKey
      if (dataset.notDraggable) {
        const message = this.$t(
          'dashboard.noTaskChangeRights',
          { context: columnKey, num: this.getTask(jid).num },
        )
        Notify.create({
          message,
          color: 'negative',
          textColor: 'white',
          position: 'top',
          timeout: 1000,
          actions: [{ label: this.$t('common.close'), color: 'white' }],
          badgeClass: 'hidden',
        })
      } else {
        const from = this.taskDragMetadata.fromColumn
        uiStore.actions.setTaskDragData({ from, columnKey, jid })
      }
    },
    async handleDragChange (evt) {
      // no need to react to 'removed' event since we update the list ourselves
      const taskElementJid = evt?.added?.element?.jid
      if (
        !taskElementJid ||
        taskElementJid !== this.draggedTaskData.jid ||
        !this.isDraggable(taskElementJid)
      ) return

      uiStore.actions.setTaskDragData({ to: this.taskDragMetadata.fromColumn })

      const { jid, to, from, columnKey } = this.draggedTaskData
      const payload = {
        jid,
        task: {},
        early: true,
      }

      switch (columnKey) {
        case 'tags': {
          const tags = [...this.getTask(this.draggedTaskData.jid).tags]
          if (to === undefined) tags.splice(0)
          else {
            const insertAt = tags.indexOf(from)
            const replaceWith = tags.includes(to) ? [] : [to]
            tags.splice(insertAt, 1, ...replaceWith)
          }
          payload.task.tags = tags
          break
        }
        case 'section':
          payload.task.section = to || null
          break
        case 'assignee':
          payload.task.assignee = to
          break
        case 'task_status':
          payload.task.task_status = to
          break
        case 'owner':
          payload.task.owner = to
          break
        case 'importance':
          payload.task.importance = Number(to) || null
          break
        default:
          return
      }

      await tasksStore.actions.edit(payload)
    },
    handleDragEnd () {
      const nullDragObject = {
        to: null,
        from: null,
        jid: null,
        columnKey: null,
      }
      uiStore.actions.setTaskDragData(nullDragObject)
    },
    isDraggable (jid) {
      return jid && this.getTask(jid)?.changeableFields?.includes(this.draggedTaskFieldKey)
    },
    handleVisibility (jid, visible) {
      if (visible) {
        visible && !this.seenTasks.includes(jid) && this.seenTasks.push(jid)
        const needMore = this.needToLoadMore()
        needMore && !this.isLoading && this.loadTasks()
      }
    },
    async loadTasks () {
      this.isLoading = true

      const num = this.tasksNotInStore
        .slice(0, this.paginationLimit)
        .map(task => task.num)

      if (num.length > 0) {
        try {
          await tasksStore.actions.loadTasks({ num })
        } catch (e) {
          console.log('error loading', e)
        }
      }

      this.isLoading = false
    },
    needToLoadMore () {
      const numUnseenLocally = this.sortedTasks.length - this.seenTasks.length
      const threshold = 20
      return numUnseenLocally < threshold
    },
    isThisTaskOnScreen (task) {
      if (!task) return
      if (this.notHoverable) return

      return this.currentChat === task.jid
    },
  },
  computed: {
    draggedTaskData () {
      return uiStore.state.taskDesk.draggedTaskData
    },
    ...mapGetters([
      'currentChat',
      'profile',
    ]),
    draggedTaskFieldKey () {
      switch (this.taskDragMetadata.groupByKey) {
        case 'status':
          return 'task_status'
        case 'tag':
          return 'tags'
        default:
          return this.taskDragMetadata.groupByKey
      }
    },
    dragEnabled () {
      switch (this.taskDragMetadata.groupByKey) {
        case 'assignee':
        case 'section':
        case 'status':
        case 'owner':
        case 'tag':
        case 'importance':
          return true
      }
      return false
    },
    team () {
      return window.currentTeamId
    },
    intersectionSize () {
      const type = this.type
      const display = this.compactList ? 'compact' : 'full'
      return this.defaultCardsSizes[type][display]
    },
    cardType () {
      let type
      if (this.type === 'taskDesk') {
        if (this.compactList) {
          type = 'task-desk-card-compact'
        } else {
          type = 'task-desk-card-full'
        }
      } else {
        if (this.compactList) {
          type = 'task-bar-card-compact'
        } else {
          type = 'task-bar-card-full'
        }
      }
      return type
    },
    taskDeskCardProps () {
      const props = {
        class: 'q-mx-xs q-mb-md q-mt-xs',
      }
      return props
    },
    taskBarCardProps () {
      const props = {
        style: 'margin-bottom: 12px;',
      }
      return props
    },
    taskCardProps () {
      const commonProps = {
        notHoverable: this.notHoverable,
        showDir: true,
        compact: this.compactList,
        quickDone: this.quickDone,
        dragMetadata: this.taskDragMetadata,
        type: this.type,
      }
      const props = {
        ...commonProps,
        ...this.type === 'taskDesk' ? this.taskDeskCardProps : this.taskBarCardProps,
      }
      return props
    },
    tasks () {
      // if no tasks jids passed from parent - get all tasks from store
      const result = this.jids
        ? this.jids.map(jid => this.getTask(jid)).filter(t => !!t)
        : { ...tasksStore.state.data }
      return result
    },
    tasksNotInStore () {
      return this.wantedTasks.filter(task => !this.getTask(task.jid))
    },
    filteredTasks () {
      // TODO: подгрузка задач
      const result = Object.values(this.tasks).filter(t => {
        if (!t) return false // !: REWRITE - pull tasks from back instead of ignoring them

        if (this.jids) return true // Антон сказал, что это ещё пригодится

        if (t.tabs && !t.tabs.includes(this.tabKey)) return false

        if (this.filters.owner.length > 0 &&
          !this.filters.owner.some(o => o === t.owner)) return false

        if (this.filters.assignee.length > 0 &&
          !this.filters.assignee.some(o => o === t.assignee)) return false

        if (this.filters.section === false && t.section) return false

        if (this.filters.section && t.section !== this.filters.section) return false

        return true
      }).map(task => task)
      return result
    },
    unreadFirst () { // not using, but need may be
      return this.profile.unreadFirst
    },
    sortedTasks () {
      return sortTasks(this.filteredTasks, this.sort)
    },
    showSearchEverywhere () {
      return false
      // return (this.filters.tags.length || this.filters.searchQuery) && !(this.filters.new && this.filters.done && this.filters.in && this.filters.out && this.filters.copy)
    },
    showNothingFound () {
      return !tasksStore.state.loading && this.sortedTasks.length === 0
    },
  },
}
