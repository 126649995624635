//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TaskList from '@/components/Tasks/TaskList'
import { uiStore } from '@/store'

export default {
  name: 'TaskDeskColumn',
  components: {
    TaskDeskColumnHeader: () => import('./TaskDeskColumnHeader'),
    TaskList,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    sortKey: {
      type: String,
      required: true,
    },
    groupByKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    taskDeskCompactCards () {
      return uiStore.getters.taskDeskCompactCards
    },
    dragMetadata () {
      const { section, tag, status, jid, importance } = this.meta
      return {
        groupByKey: this.groupByKey,
        fromColumn: section || tag || status?.name || jid || importance,
      }
    },
    jids () {
      return (this.tasks || []).map(entry => entry.jid)
    },
  },
}
